import React from 'react';
import CKEditor from '../ReactCKEditor';

interface PropsTypes {
  // eslint-disable-next-line no-unused-vars
  events?: { [key: string]: (ckEvent: any) => void };
  config?: { [key: string]: any };
  content?: string;
  name?: string;
  container: React.MutableRefObject<HTMLDivElement>;
}

const MemberNotificationContentEditorRender = ({
  name = 'htmlEditor',
  container,
  events = {},
  config = {},
  content = null,
}: PropsTypes) => (
  <CKEditor
    name={name}
    content={content}
    config={{
      removeButtons:
        'Underline,Subscript,Superscript,Anchor,Image,Flash,Table,About,Copy,Paste,PasteText,PasteFromWord,Cut,Scayt',
      extraPlugins: 'strinsert,uploadimage',
      removePlugins: 'elementspath',
      autoParagraph: false,
      enterMode: 3,
      height: 70 * (container.current.clientHeight / 100),
      protectedSource: /<!\[CDATA\[[\s\S]*?\]\]>/g,
      ...config,
    }}
    events={{
      pluginsLoaded(event) {
        event.editor.dataProcessor.dataFilter.addRules({
          elements: {
            script() {
              return false;
            },
          },
        });
      },
      ...events,
    }}
  />
);

const MemberNotificationContentEditor = (props: PropsTypes) =>
  props.container.current && <MemberNotificationContentEditorRender {...props} />;

export default MemberNotificationContentEditor;
