import React from 'react';
import { connect } from 'react-redux';
import { getCurrentRequestModelId } from 'src/reducers/campaignEditor';

import CtnLang from './CtnLang';
import utils from 'src/utils/utils';
import EmailObjectLabel from './EmailObjectLabel';
import XplorActiveNotifyObjectEditor from 'src/components/common/contentEditor/XplorActiveNotifyObjectEditor';
import EmailContentLabel from './EmailContentLabel';
import XplorActiveNotifyContentEditor from 'src/components/common/contentEditor/XplorActiveNotifyContentEditor';

interface PropsTypes {
  isWritable: boolean;
  // eslint-disable-next-line no-unused-vars
  onContentChange: (content: string) => void;
  // eslint-disable-next-line no-unused-vars
  onObjectChange: (object: string) => void;
  fields: { fields: any };
  currentAction: { content?: any; messageModel?: any; customTemplate?: { generic?: boolean } };
}

const XplorActiveNotifEditorRender = ({
  currentAction,
  fields,
  onContentChange,
  onObjectChange,
  isWritable,
}: PropsTypes) => (
  <>
    <div style={{ width: '100%', display: 'flex' }}>
      <EmailObjectLabel
        title={utils.getLang('smartmessaging.contentEditor.xploractivepush.object')}
      />
      <div style={{ marginRight: 0, marginLeft: 'auto' }}>
        <CtnLang />
      </div>
    </div>
    <XplorActiveNotifyObjectEditor
      config={{
        readOnly: !isWritable,
        fields: fields.fields.objectFields,
      }}
      events={{
        change: event => {
          onObjectChange(event.editor.getData());
        },
      }}
      object={currentAction.content.object}
    />
    <EmailContentLabel
      skipOptoutControl
      title={utils.getLang('smartmessaging.contentEditor.xploractivepush.content')}
    />
    <XplorActiveNotifyContentEditor
      config={{
        readOnly: !isWritable,
        fields: fields.fields.contentFields,
      }}
      events={{
        change: event => {
          const data = event.editor.getData();
          onContentChange(data);
        },
        instanceReady: event => {
          if (currentAction.content.content) event.editor.setData(currentAction.content.content);
        },
      }}
    />
  </>
);

const mapStateToProps = state => ({
  currentAction: state.campaignEditor.actions.currentAction,
  currentRequestModelId: getCurrentRequestModelId(state),
});

const actionCreators = {
  onContentChange: data => ({ type: 'ON_PUSH_NOTIFY_CONTENT_CHANGE', value: data }),
  onObjectChange: data => ({ type: 'ON_PUSH_NOTIFY_OBJECT_CHANGE', value: data }),
};

export default connect(mapStateToProps, actionCreators)(XplorActiveNotifEditorRender);
