import React from 'react';
import PropTypes from 'prop-types';
import CKEditor from '../ReactCKEditor';

const SmsEditor = ({ name = 'smsEditor', content, config = {}, events = {} }) => (
  <div>
    <CKEditor
      name={name}
      config={{
        removeButtons:
          'Underline,Subscript,Superscript,' +
          'Source,Anchor,Maximize,About,Copy,Paste,PasteText,PasteFromWord,' +
          'Cut,Scayt,Undo,Redo,Form,Checkbox,Radio,TextField,Textarea,Select,Button,' +
          'ImageButton,HiddenField,Bold,Italic,Underline,Strike,Subscript,Superscript,' +
          'RemoveFormat,NumberedList,BulletedList,Outdent,Indent,Blockquote,CreateDiv,' +
          'JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,' +
          'BidiLtr,BidiRtl,CreatePlaceholder,' +
          'Image,Flash,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,InsertPre,Styles,Format,Font,FontSize,Unlink,Link',
        extraPlugins: 'strinsert',
        removePlugins: 'colorbutton,elementspath',
        autoParagraph: false,
        enterMode: 2,
        allowedContent: true,
        ...config,
      }}
      events={events}
      content={content}
    />
  </div>
);

SmsEditor.propTypes = {
  content: PropTypes.string.isRequired,
  config: PropTypes.object,
  events: PropTypes.object,
  name: PropTypes.string,
};

export default SmsEditor;
