import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';

import { makeStyles } from 'tss-react/mui';
import { Paper, Table, TableBody, Typography } from '@mui/material';
import { format } from 'date-fns';
import HistoryListHead from './HistoryListHead';
import utils from '../../../utils/utils';
import hUtils from './utils';
import ClickedLinkHistory from './ClickedLinkHistory';
import SummaryItem from './SummaryItem';

const useStyles = makeStyles()(theme => ({
  paper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',
  },
  nPaper: { marginTop: '24px' },
  table: {
    tableLayout: 'fixed',
  },
  tableWrapper: {
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
  },
  topContainer: {
    position: 'relative',
    margin: '8px',
    overflow: 'auto',
  },
}));

const NoResult = () => (
  <Typography
    component="span"
    color="textSecondary"
    style={{
      display: 'inline',
      fontSize: '0.975rem',
    }}
  >
    {utils.getLang(`smartmessaging.list.noResult`)}
  </Typography>
);

function HistorySummary({
  excludedProperties,
  rowSpanCols,
  summaryExcludedProperties,
  doSort,
  sortConfig,
  summary,
  clickedLinks,
}) {
  const { classes } = useStyles();
  return (
    <div className={classes.topContainer}>
      <Paper className={classes.paper} elevation={0}>
        <div className={classes.tableWrapper}>
          {!!summary.item.length && (
            <Table aria-labelledby="tableTitle" className={classes.table}>
              <HistoryListHead
                sort={doSort}
                sortConfig={sortConfig}
                columns={hUtils.getColumns(summary.item[0], [
                  ...excludedProperties,
                  ...summaryExcludedProperties,
                ])}
                sortable={[]}
              />
              <TableBody>
                <>
                  <SummaryItem
                    columns={hUtils.getColumns(summary.item[0], [
                      ...excludedProperties,
                      ...summaryExcludedProperties,
                    ])}
                    key={summary.item[0].id}
                    item={summary.item[0]}
                    specificRender={{
                      date: () =>
                        `du ${format(new Date(summary.boundDates.start), 'dd/MM/yyyy')} au ${format(
                          new Date(summary.boundDates.end),
                          'dd/MM/yyyy'
                        )}`,
                      clubName: () => summary.clubList.join(', '),
                      optinType: () =>
                        summary.optinTypeList
                          .map(o =>
                            utils.getLang(
                              `smartmessaging.optinType.${o !== 'null' ? o : 'NO_OPTIN'}`
                            )
                          )
                          .join(', '),
                    }}
                    rowSpanCols={rowSpanCols}
                    rowSpan={summary.item.length || 1}
                  />
                  {summary.item.slice(1).map(i => (
                    <SummaryItem
                      columns={hUtils.getColumns(i, [
                        ...excludedProperties,
                        ...rowSpanCols,
                        ...summaryExcludedProperties,
                      ])}
                      key={uuid()}
                      item={i}
                      rowSpanCols={rowSpanCols}
                      rowSpan={summary.item.length || 1}
                    />
                  ))}
                </>
              </TableBody>
            </Table>
          )}
          {!summary.item.length && <NoResult />}
        </div>
      </Paper>
      <Paper className={[classes.paper, classes.nPaper].join(' ')} elevation={0}>
        <div className={classes.tableWrapper}>
          <ClickedLinkHistory clickedLinks={clickedLinks} />
        </div>
      </Paper>
    </div>
  );
}

HistorySummary.propTypes = {
  excludedProperties: PropTypes.array.isRequired,
  summaryExcludedProperties: PropTypes.array.isRequired,
  rowSpanCols: PropTypes.array.isRequired,
  doSort: PropTypes.func.isRequired,
  sortConfig: PropTypes.object.isRequired,
  summary: PropTypes.object.isRequired,
  clickedLinks: PropTypes.array.isRequired,
};

export default HistorySummary;
